import React from "react";
import { useMutation, useQuery } from "react-query";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Box, colors, Select, spacing } from "triangle-ui";

import Button from "../components/Button";
import { Card, CardContainer, CardContent } from "../components/Card";
import Link from "../components/Link";
import { Text } from "../components/Text";
import api from "../utils/api";

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  min-width: 1024px;
  // position: relative;
`;

const Content = styled.div`
  padding: ${spacing.xlarge};
`;

const Header = styled.div`
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.black100};
  display: flex;
  justify-content: space-between;
  padding: ${spacing.xlarge};
  position: sticky;
  top: 0;
  z-index: 1;

  @media (max-width: ${spacing.tablet}) {
    position: static;
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

const Main = styled.div`
  flex: 1;
  // position: relative;
  border-left: 1px solid ${colors.black100};
`;

const Nav = styled.div``;

const NavButton = styled(Link)`
  box-shadow: none;
  color: ${colors.black};
  display: inline-block;
  justify-content: flex-start;
  margin-left: 0;
  padding ${spacing.small} 0;
  width: 100%;

  &:hover {
    box-shadow: none;
    color: ${colors.blue};
  }

  & + & {
    margin-top: ${spacing.medium};
  }
`;

const Side = styled.div`
  align-self: flex-start;
  flex-shrink: 0;
  position: sticky;
  padding: ${spacing.xlarge};
  top: 0;
  width: 256px;

  @media (max-width: ${spacing.tablet}) {
    position: static;
  }
`;

const Dashboard = () => {
  const app: any = useQuery(["apps", "current"], () => api("/v1/apps/current"));
  const apps: any = useQuery("apps", () => api("/v1/apps"));
  const flags: any = useQuery(["apps", "current", "flags"], () => api("/v1/apps/current/flags"));
  const user: any = useQuery(["users", "current"], () => api("/v1/users/current"));

  const navigate = useNavigate();
  const logout = useMutation(() => api("/v1/logout", { body: {}, method: "POST" }), {
    onError: (error: any) => window.alert(error.message),
    onSuccess: (data) => {
      // navigate("/login");
      window.location.href = "/login";
    },
  });

  if (app.isLoading || apps.isLoading || flags.isLoading || user.isLoading) {
    return (
      <Box flex={{ alignItems: "center", justifyContent: "center" }} height="100vh">
        Loading...
      </Box>
    );
  } else if (apps.error) {
    return (
      <Box flex={{ alignItems: "center", justifyContent: "center" }} height="100vh">
        Error: {apps.error.message}
      </Box>
    );
  } else if (!apps.data.items.length) {
    return <Navigate to="/onboarding" />;
  }

  return (
    <Container>
      <Side>
        <Logo>
          <img alt="Triangle logo" src="/triangle.png" style={{ height: "32px" }} />
        </Logo>
        <Nav>
          <NavButton to="/">Home</NavButton>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <NavButton to="/vaults">Vaults</NavButton>
          <NavButton to="/wallets">Wallets</NavButton>
          <NavButton to="/accounts">Accounts</NavButton>
          <NavButton to="/transactions">Transactions</NavButton>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <NavButton to="/rpcs">RPC Proxies</NavButton>
          <NavButton to="/nodes">Dedicated Nodes</NavButton>
          <NavButton to="/webhooks">Webhooks</NavButton>
          <NavButton to="/events">Events</NavButton>
          <NavButton to="/logs">Logs</NavButton>
          <NavButton to="/apikeys">API Keys</NavButton>
          <NavButton to="/settings">Settings</NavButton>
        </Nav>
      </Side>
      <Main>
        <Header>
          <div style={{ width: "256px" }}>
            <Select
              defaultValue={{
                label: `${app.data.name} (${app.data.id})`,
                value: app.data.id,
              }}
              menuPortalTarget={document.body}
              name="appId"
              onChange={(value: any) => {
                window.location.href = `/?app_id=${value.value}`;
              }}
              options={apps.data.items.map((app: any) => ({
                label: `${app.name} (${app.id})`,
                value: app.id,
              }))}
            />
          </div>
          <Box flex={{ alignItems: "center" }}>
            <div>
              {user.data.name} ({user.data.email})
            </div>
            <Button onClick={() => logout.mutate()}>Logout</Button>
          </Box>
        </Header>
        <Content>
          {flags.data.is_sunset_enabled && (
            <CardContainer style={{ boxShadow: `0 0 0 2px ${colors.red}` }}>
              <CardContent style={{ background: colors.red100 }}>
                <Text size="medium" weight="medium">
                  Sunset
                </Text>
              </CardContent>
              <CardContent
                style={{
                  background: colors.red100,
                  borderTop: `2px solid ${colors.red200}`,
                }}
              >
                <Text size="small">
                  Your app has been sunsetted. Please email hello@triangleplatform.com for more
                  information.
                </Text>
              </CardContent>
            </CardContainer>
          )}
          <Outlet />
        </Content>
      </Main>
    </Container>
  );
};

export default Dashboard;
